// import React from 'react'
// import Layout from '../../layouts/globalLayout/Layout.page'

import { Alert, Button, Container, Grid, IconButton, InputAdornment, Pagination, Stack, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useColumns } from "./hooks/allUsers.hook"
import { useCallback, useEffect, useState } from "react"
import { useLazyGetAllUserListQuery } from "../../lib/redux/api/users.api"
import { useNavigate } from "react-router-dom"
import { useGetPageNumber } from "../../hooks/useGetPageNumber.hook"
import { useTitle } from "../../hooks/useTitle.hook"
import { FaSearch } from "react-icons/fa"
import { MdClear } from "react-icons/md"
import { debounce } from "../../utils"
import config from "../../config"
import PageHeading from "../../components/_ui/pageHeading/PageHeading.component"




export default function AllUsers() {
  useTitle('All Users')
  const { columns } = useColumns()
  const navigate = useNavigate()
  const pageNumber = useGetPageNumber()
  const [searchTerm, setSearchTerm] = useState<string>('');

  const paginationModel = { page: Number(pageNumber || 1) - 1, pageSize: config.tableRowLimit }
  const [getAllUserList, { data, isError, isLoading, isFetching, isUninitialized }] = useLazyGetAllUserListQuery()

  const setPaginationModel = (page: number) => {
    navigate(`/admin/all-users?page=${page}`)
  }

  useEffect(() => {
    getAllUserList({
      search: searchTerm || '',
      page: (paginationModel.page + 1),
      page_size: paginationModel.pageSize,
      order_by: 'created',
      order_type: "dsc",
    })
  }, [paginationModel.page])

  // Create debounced search function
  const debouncedSearch = debounce((value: string) => {
    setPaginationModel(1)
    getAllUserList({
      search: value || '',
      page: (paginationModel.page + 1),
      page_size: paginationModel.pageSize,
      order_by: 'created',
      order_type: "dsc",
    })
  }, 1000);


  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedSearch(value);
  }, []);


  if (isError) return <Container><Alert severity="error" >Something Went Wrong</Alert></Container>


  return (
    <>
      {/* Heading and the search filed */}

      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='space-between' alignItems='center'>
        <PageHeading>All Users</PageHeading>

        <Stack direction='row' gap={2}>
          <TextField label="Search" variant="outlined" size="small" sx={{ width: { xs: 1, sm: 300 } }}
            value={searchTerm}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small"><FaSearch /></IconButton>
                </InputAdornment>
              ),
            }}
          />
          {Boolean(searchTerm) && <Button variant='outlined' onClick={() => { setSearchTerm(''); debouncedSearch('') }}><MdClear size='1.5rem' /></Button>}
        </Stack>
      </Stack>


      <Stack width={1}>
        {/* == Table ==  */}
        {isError ? <Alert severity="error" >Something Went Wrong</Alert> :
          <DataGrid
            autoHeight
            columns={columns}
            rowSelection={false}
            paginationMode='server'
            disableColumnMenu={true}
            loading={isUninitialized || isFetching}
            paginationModel={paginationModel}
            rowCount={data?.total_users || 0}
            onRowDoubleClick={(data) => console.log('123', data)}
            onRowClick={(params) => navigate(`/admin/users-detail/${params.id}`)}
            rows={data?.users?.map((item: any, index: number) => ({ sno: index, id: item?._id?.$oid, ...item })) || []}
            slots={{
              pagination: () => (
                <Pagination
                  page={paginationModel.page + 1}
                  onChange={(_, newPage) => setPaginationModel(newPage)}
                  count={data?.total_users ? Math.ceil(Number(data?.total_users) / paginationModel.pageSize) : 0}
                />
              ),
              noRowsOverlay: () => <Typography variant='body2' color='text.secondary' className='center' height={1}>No Record Found</Typography>
            }}
          />}
      </Stack>
    </>
  )
}
