import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from '@mui/material'
import { ConfirmationPopupProps } from './ConfirmationPopup.type'
import { LoadingButton } from '@mui/lab';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function ConfirmationPopup(props: ConfirmationPopupProps) {

  const { title, description, callback, actionButtonTitle, closeButtonTitle, isOpen, handleClosePopup, isButton = true, isLoading, } = props

  const handleActionPerform = () => {
    callback()
  }
  const handleClose = () => {
    console.log('handleClose')
    handleClosePopup()
  }



  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xs' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

        {title && <DialogTitle>{title}</DialogTitle>}

        {description && <DialogContent>
          <DialogContentText id="modal-modal-description">{description}</DialogContentText>
        </DialogContent>
        }

        {isButton && <DialogActions sx={{ p: 3 }}>
          <LoadingButton variant='outlined' sx={{ fontWeight: 600 }} onClick={handleClose}>{closeButtonTitle}</LoadingButton>
          <LoadingButton loading={isLoading} variant='contained' sx={{ fontWeight: 600 }} onClick={handleActionPerform}>{actionButtonTitle}</LoadingButton>
        </DialogActions>}
      </Dialog>
    </>
  )
}
