import PageHeading from "../../components/_ui/pageHeading/PageHeading.component";
import UserDetailPage from "./component/UserDetail.component";


export default function UserDetail() {


  return (
    <>
      {/* <PageHeading variant='h4'>User Detail</PageHeading> */}
      <UserDetailPage />
    </>

  );
}
