import { useEffect } from 'react'
import config from '../config'


export const useTitle = (title: string | undefined | null) => {

  useEffect(() => {
    document.title = title ? `${title} | ${config.brandName}` : `${config.brandName}`;
  }, [title]);

}