import { Controller, useForm } from 'react-hook-form';
import { FormDataType, RestaurantFormDataProps, schema } from './RestaurantForm.type';
import { Grid, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useColumns } from '../hooks/useGroupLists.hook';
import { DataGrid } from '@mui/x-data-grid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';



export default function RestaurantForm(props: RestaurantFormDataProps) {
  const { formData, onUpdate, updateMatchFormLoading, restaurantIndex,
    isAllRFetching, isAllRLoading, allRestaurantData, defaultRestaurantObject } = props

  const [loadingIndex, setLoadingIndex] = useState<Number | null>(null)
  const { columns } = useColumns()

  const { control, handleSubmit, setValue, formState: { errors } } = useForm<FormDataType>({
    resolver: yupResolver(schema),
    defaultValues: {
      restaurant_id: formData.restaurant_id || '',
      restaurant_address: formData.restaurant_address || '',
      restaurant_title: defaultRestaurantObject[0],
      google_map_link: formData.google_map_link,
    }
  });


  const onSubmit = async (data: FormDataType) => {
    await onUpdate({ ...data, group_id: formData.group_id, eventId: formData.eventId })
    setLoadingIndex(null)

  }

  const handleRestaurantSelect = (selectedRestaurant: any) => {
    if (selectedRestaurant) {
      // setValue('restaurant_title', selectedRestaurant);
      // trigger()
      setValue('restaurant_id', selectedRestaurant._id.$oid);
      setValue('restaurant_address', selectedRestaurant.Address);
      setValue('google_map_link', selectedRestaurant.google_map_link);
    }
    else {
      setValue('restaurant_id', '');
      setValue('restaurant_address', '');
      setValue('google_map_link', '');
    }
  };


  return (
    <Stack component='form' onSubmit={handleSubmit(onSubmit)} mt={3} p={2} border={1} borderRadius={3} borderColor='primary.main'>
      <Grid container spacing={2} rowGap={2}>

        <Grid item xs={12} md={8}>
          <DataGrid
            autoPageSize
            rows={formData.users?.map((item: any, index: number) => ({ sno: index, id: item?.user_id, ...item })) || []}
            autoHeight
            columns={columns}
            rowSelection={false}
            disableRowSelectionOnClick
            hideFooter
            slots={{
              noRowsOverlay: () => <Typography variant='body2' color='text.secondary' className='center' height={1}>No Record Found</Typography>
            }}
          />
        </Grid>

        <Grid item xs={12} md={4} >
          <Stack gap={2}>

            <Controller name='restaurant_title'
              control={control}
              defaultValue={null as any}
              render={({ fieldState: { error }, field: { ref, onChange, ...restField } }) =>
                <Autocomplete
                  {...restField}
                  loading={isAllRLoading || isAllRFetching}
                  options={allRestaurantData?.restaurants_data}
                  onChange={(_, value) => { onChange(value); handleRestaurantSelect(value) }}
                  getOptionLabel={(option: any) => option.Name}
                  isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                  renderInput={(params) => <TextField {...params} placeholder='Restaurant Title' inputRef={ref} error={!!error} helperText={error?.message} inputProps={{ ...params.inputProps, autoComplete: 'new-password' }} />}
                />
              }
            />

            <Controller
              name="restaurant_id"
              control={control}
              render={({ field: { value, onChange, onBlur, ...rest } }) => (
                <TextField {...rest} value={value} onChange={onChange}
                  placeholder='Restaurant Id' disabled
                  helperText={errors.restaurant_id?.message} error={!!errors.restaurant_id?.message} variant='outlined'
                />
              )}
            />

            <Controller
              name="restaurant_address"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <TextField value={value} onChange={onChange} {...rest}
                  placeholder='Restaurant Address'
                  helperText={errors.restaurant_address?.message} error={!!errors.restaurant_address?.message} variant='outlined' />
              )}
            />
            <Controller
              name="google_map_link"
              control={control}
              render={({ field }) => (
                <TextField {...field} placeholder='Google map link'
                  helperText={errors.google_map_link?.message} error={!!errors.google_map_link?.message} variant='outlined' />
              )}
            />
            <LoadingButton variant='outlined' loading={updateMatchFormLoading && restaurantIndex === loadingIndex} type="submit" onClick={() => setLoadingIndex(restaurantIndex)}>Update</LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}
