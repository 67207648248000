import { Button, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormData, schema } from './LoginForm.config';
import { Controller, useForm } from 'react-hook-form';
import { style } from './LoginForm.style';
import { useAuthLoginMutation } from '../../../../lib/redux/api/auth.api';
import { LoadingButton } from '@mui/lab';


function LoginForm() {
  const { handleSubmit, control, formState: { errors } } = useForm<FormData>({ resolver: yupResolver(schema) })

  const [authLogin, { isLoading }] = useAuthLoginMutation()

  const onSubmit = async (data: FormData) => {
    await authLogin(data)
  }


  return (
    <Stack sx={style.mainBox}>

      <Stack alignItems='center' width={{ xs: 1, md: 450 }} gap={3} py={5} px={3} border={1} borderColor='divider' borderRadius={3} boxShadow={4} >
        <Typography component='h1' variant='h2' >Login</Typography>

        <Stack component='form' gap={3} onSubmit={handleSubmit(onSubmit)} width={1} >
          <Stack spacing={1}>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <Controller control={control} name='email'
              render={({ field }) => (
                <TextField  {...field} id='email' placeholder='Enter your email' helperText={errors.email?.message} error={!!errors.email?.message} type='text' variant='outlined' />
              )} />
          </Stack>

          <Stack spacing={1}>
            <InputLabel htmlFor='email'>Password</InputLabel>
            <Controller control={control} name='password'
              render={({ field }) => (
                <TextField {...field} placeholder='Enter your password' type='password' helperText={errors.password?.message} error={!!errors.password?.message} variant='outlined' />
              )} />
          </Stack>

          {/* <Typography className='content-right' variant='body3'><Link href='/auth/forgot-password'>Forgot password</Link></Typography> */}
          <LoadingButton loading={isLoading} variant='contained' size='large' type='submit' fullWidth>Sign in</LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LoginForm
