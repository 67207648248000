import { Outlet, RouteObject } from 'react-router-dom'
import AuthRoutes from './components/auth/AuthRoutes'
import Login from '../pages/auth/login/index.page'



export const authRoutes: RouteObject[] = [
  {
    path: '/auth',
    element: <AuthRoutes><Outlet /></AuthRoutes>,
    children: [
      { path: 'login', element: <Login /> },
      // { path: 'forgot-password', element: <ForgotPassword /> },
      // { path: 'reset-password', element: <ResetPassword /> },
    ]
  }
]