import { theme } from './utils'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { store } from './lib/redux/store/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import routes from './routes'
import './assets/style/globals.css'



export default function App() {


  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline enableColorScheme />
          <Toaster position='top-right' />
          <RouterProvider router={routes} />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider >
  )
}
