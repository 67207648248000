import { Typography, Container, Button, Stack, Box } from '@mui/material';
import { useTitle } from '../../hooks/useTitle.hook';



const NotFoundPage = () => {
  useTitle('Not Found')


  return (
    <Container maxWidth="md">
      <Box sx={{ height: '100vh' }}>
        <Stack height={1} direction='column' justifyContent='center' alignItems='center' gap={4}>
          <Typography variant="h2" component="h1">404 Page Not Found</Typography>
          <Typography variant="body1" >The page you requested cannot be found.</Typography>
          <Button variant="contained" href="/" color="primary">Go back to Home</Button>
        </Stack>
      </Box>
    </Container>
  )
}


export default NotFoundPage;
