import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { TableUserDetailModalProps } from './TableUserDetailModal.type'
import UserDetailPopup from '../../../../components/_ui/userDetailPopup/UserDetailPopup.components'
import { style } from './TableUserDetailModal.style'



export default function TableUserDetailModal(props: TableUserDetailModalProps) {
  const { data, id } = props
  const [openUserProfile, setOpenUserProfile] = useState(false)


  return (
    <>
      <Typography variant='body2' sx={style.userName} onClick={() => setOpenUserProfile(!openUserProfile)}>
        {data.full_name}
      </Typography>

      <UserDetailPopup id={id} isOpen={openUserProfile} title='User Detail' handleClosePopup={() => setOpenUserProfile(false)} />
    </>
  )
}
