
import { Container } from '@mui/material';
import AuthLayout from '../../../layouts/authLayout/AuthLayout.page';
import LoginForm from './component/LoginForm.component';
import { useTitle } from '../../../hooks/useTitle.hook';



const Login: React.FC = () => {
  useTitle('Login')


  return (
    <AuthLayout title='Login'>
      <Container>
        <LoginForm />
      </Container>
    </AuthLayout>

  );
};

export default Login;
