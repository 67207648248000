import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useActionAbuseUserMutation } from '../../../../lib/redux/api/users.api'
import { Stack } from '@mui/material'
import { TableAction } from '../../../../dtos/TableAction.dto'
import ConfirmationPopup from '../../../../components/_ui/confirmationPopup/ConfirmationPopup.components'



export default function TableActions({ data }: { data: TableAction }) {
  const [openPopup, setPopUpOpen] = useState(false)
  const [actionAbuseUser, { isLoading }] = useActionAbuseUserMutation()

  const handleOpen = () => setPopUpOpen(true)
  const handleClose = () => setPopUpOpen(false)

  const handleBlockUsers = () => {
    actionAbuseUser({ groupID: data.group_id, reported_by: data.reported_by, userId: data.user_id })
      .then((res) => setPopUpOpen(false))
      .catch((err) => setPopUpOpen(false))
  }


  return (
    <Stack className='center' width={1}>

      {/* === More Button === */}
      <LoadingButton variant='contained' color="error" onClick={handleOpen}>Block</LoadingButton>

      {/* === Delete Popup === */}
      <ConfirmationPopup
        title='Block User'
        description='Are you sure you want to block user?'
        actionButtonTitle='YES'
        closeButtonTitle='NO'
        isOpen={openPopup}
        handleClosePopup={handleClose}
        callback={handleBlockUsers}
        isLoading={isLoading}
      />
    </Stack>
  )
}