import { AllUsersDto } from '../../../dtos/AllUsers.dto';
import { ReportedUsers } from '../../../dtos/ReportedUser.dto';
import User from '../../../dtos/User.dto';
import { UsersFeedback } from '../../../dtos/UserFeedback.dto';
import { api } from './api.config'
// import { updateProfile } from '../slices/user.slice'
// import { setCookie } from '../../../utils/index'



export const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUserList: builder.query<AllUsersDto, { page: Number, page_size: Number, order_by: String, order_type: String, search: String }>({
      query: (data) => ({
        url: '/getAllUserList',
        method: 'POST',
        body: data,
        headers: { hideToast: 'true' },
      }),
      transformResponse: (res: AllUsersDto) => {
        console.log("res,: ", res);

        return res
      },
    }),
    getUserDetailUserId: builder.query<User, { id: string }>({
      query: (data) => ({
        url: `/getUserDetail/${data.id}`,
        method: 'GET',
      }),
      transformResponse: (res: User) => {
        return res
      },
      providesTags: ['profile']
    }),
    getAllUserFeedback: builder.query<UsersFeedback, { page: Number, page_size: Number, order_by: String, order_type: String }>({
      query: (data) => ({
        url: '/getAllUserFeedback',
        method: 'POST',
        body: data,
        headers: { hideToast: 'true' },
      }),
      transformResponse: (res: UsersFeedback) => {
        return res
      },
    }),
    getAllReportedUsers: builder.query<ReportedUsers, { page: Number, page_size: Number, order_by: String, order_type: String }>({
      query: (data) => ({
        url: '/getAllReportedUsers',
        method: 'POST',
        body: data,
        headers: { hideToast: 'true' },
      }),
      transformResponse: (res: ReportedUsers) => {
        return res
      },
      providesTags: ['block-user']
    }),
    approveUserProfile: builder.mutation<any, { userId: string, is_verified: string }>({
      query: (data) => ({
        url: '/approveUserProfile',
        method: 'POST',
        body: data,
      }),
      transformResponse: (res: any) => {
        return res
      },
      invalidatesTags: ['profile']
    }),
    actionAbuseUser: builder.mutation<any, { userId: string, groupID: string, reported_by: string }>({
      query: (data) => ({
        url: '/actionAbuseUser',
        method: 'POST',
        body: data,
      }),
      transformResponse: (res: any) => {
        return res
      },
      invalidatesTags: ['block-user']
    }),

    updatedHostVerifiedStatus: builder.mutation<any, { user_id: string, status: string }>({
      query: (data) => ({
        url: '/updatedHostVerifiedStatus',
        method: 'POST',
        body: data,
      }),
      transformResponse: (res: any) => {
        return res
      },
      invalidatesTags: ['profile']
    }),
  })
})



export const {
  useLazyGetAllUserListQuery,
  useLazyGetUserDetailUserIdQuery,
  useLazyGetAllUserFeedbackQuery,
  useLazyGetAllReportedUsersQuery,
  useApproveUserProfileMutation,
  useActionAbuseUserMutation,
  useUpdatedHostVerifiedStatusMutation
} = extendedApi


export interface IRegister {
  email: string
  password: string
  role: string
  username: string
}