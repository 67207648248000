import { Box, IconButton, Typography } from "@mui/material";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";

const BackButton = ({ path, children, variant, size, color }: any) => {
  return (
    <Box component="span">
      <IconButton component={Link} to={path} size={size || 'medium'} color={color}>
        <MdArrowBack />
        <Typography variant={variant || 'body3'}>{children}</Typography>
      </IconButton>
    </Box >
  );
};

export default BackButton;
