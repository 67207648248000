import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { UserDetailPopupProps } from './UserDetailPopup.type'
import { RxCross1 } from "react-icons/rx";
import { useEffect } from 'react';
import { useLazyGetUserDetailUserIdQuery } from '../../../lib/redux/api/users.api';
import Loader from '../Loader/Loader.components';
import config from '../../../config';
import blankImage from '../../../assets/images/blankImage.png'



export default function UserDetailPopup(props: UserDetailPopupProps) {
  const { title, isOpen, handleClosePopup, id } = props
  const [getUserDetailUserId, { isLoading, isFetching, data, isError }] = useLazyGetUserDetailUserIdQuery();

  useEffect(() => {
    if (isOpen) {
      getUserDetailUserId({ id })
    }
  }, [id, isOpen])


  const handleClose = () => {
    handleClosePopup()
  }


  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='md' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">

      {isLoading || isFetching && <DialogContent><Loader /></DialogContent>}

      {!isLoading || !isFetching && isError && <Alert severity='error'>Something went wrong</Alert>}

      {!isFetching && !isLoading && data &&
        <>
          <DialogTitle>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
              {title}
              <Stack className='cursor-pointer' onClick={handleClose}><RxCross1 size='1.5rem' /></Stack>
            </Stack>
          </DialogTitle>


          <DialogContent>
            <Stack gap={3}>
              <Grid container spacing={3} mb='2'>
                {/* User Profile Card */}
                <Grid item xs={12} md={6} lg={6}>
                  <Stack alignItems='center' gap={3} p={2}>
                    <Stack justifyContent='start' alignItems='center' gap={2}>
                      <Box height={200} width={200} borderRadius={3} overflow='hidden'>
                        {data?.profile_pictures && data.profile_pictures?.length > 0 ? (
                          <img src={`${config?.apiBaseUrl}/${data.profile_pictures[0]}`} alt='image' style={{ objectFit: 'cover' }} />
                        ) : (
                          <img src={blankImage} alt='image' style={{ objectFit: 'cover' }} />
                        )}
                      </Box>

                      <Typography variant='h5' textAlign='center'>{data?.full_name}</Typography>
                      <Typography variant='body1' textAlign='center'>{data?.email}</Typography>
                    </Stack>

                  </Stack>

                </Grid>



                {/* User Detail Page */}
                <Grid item xs={12} md={6} lg={6}>
                  <Box p={2}>
                    <Stack spacing={2} alignItems='flex-start'>

                      <Stack direction='row' gap={3} alignItems='center'>
                        <Typography variant='body2' fontWeight={700}>Status:</Typography>
                        {data?.status === 'active' ? <Typography color='success.main'>Active</Typography>
                          : data?.status === 'blocked' ? <Typography color='error.main'>Blocked</Typography>
                            : <Typography color='error.main'>Deactive</Typography>
                        }

                      </Stack>

                      <Stack direction='row' gap={3} alignItems='center' justifyContent='space-around'>
                        <Typography variant='body2' fontWeight={700} >Phone:</Typography>
                        <Typography variant='body1'>{data?.phone ? data?.phone : 'Phone number is not available'}</Typography>
                      </Stack>

                      <Stack direction='row' gap={6} alignItems='center' justifyContent='space-'>
                        <Typography variant='body2' fontWeight={700} alignSelf='flex-start' >Bio:</Typography>
                        <Typography variant='body1'>{data?.bio?.trim() ? data?.bio?.trim() : 'Bio is not available'}</Typography>
                      </Stack>

                      <Stack direction='row' gap={4.5} alignItems='center'>
                        <Typography variant='body2' fontWeight={700} >DOB:</Typography>
                        <Typography variant='body1'>{data?.dob ? data?.dob : 'DOB is not available'}</Typography>
                      </Stack>

                      <Stack direction='row' gap={4.5} alignItems='center'>
                        <Typography variant='body2' alignSelf='start' fontWeight={700}>Address:</Typography>
                        <Typography variant='body1'>{data?.city ? data?.city : 'Address is not available'}</Typography>
                      </Stack>

                      <Stack direction='row' gap={4.5} alignItems='center'>
                        <Typography variant='body2' alignSelf='start' fontWeight={700}>Latitude:</Typography>
                        <Typography variant='body1'>{data?.geolocation?.lat ? data?.geolocation?.lat : 'Latitude is not available'}</Typography>
                      </Stack>

                      <Stack direction='row' gap={4.5} alignItems='center' >
                        <Typography variant='body2' alignSelf='start' fontWeight={700}>Longitude:</Typography>
                        <Typography variant='body1'>{data?.geolocation?.long ? data?.geolocation?.long : 'Longitude is not available'}</Typography>
                      </Stack>
                    </Stack>

                  </Box>
                </Grid>
              </Grid>
            </Stack >
          </DialogContent>
        </>
      }
    </Dialog>
  )
}
