import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import TableUserDetailModal from '../userDetailModal/TableUserDetailModal.component'



export const useColumns = () => {


  const columns: GridColDef<any>[] = [
    {
      field: 'full_name', headerName: 'Full Name', flex: 1, minWidth: 130, renderCell: (params) => (
        <TableUserDetailModal data={params.row} id={params.row.id} />
      )
    },
    {
      field: 'city', headerName: 'Address', flex: 1, minWidth: 200, renderCell: (params) => (
        <Typography variant='body2'>{params.row.city}</Typography>
      )
    },
    {
      field: 'geolocation.lati', headerName: 'Latitude', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography variant='body2'>{params.row?.geolocation?.lat}</Typography>
      )
    },
    {
      field: 'geolocation.long', headerName: 'Longitude', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography variant='body2'>{params.row?.geolocation?.long}</Typography>
      )
    },
    // { field: 'action', headerName: 'Action', width: 70, sortable: false, renderCell: (params) => <TableActions data={params.row} editUrl={`/blog/edit/${params.row.id}`} deleteCallBack={handleRemoveRow} /> },
  ]


  return { columns }
}