import { api } from './api.config'



export const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventGroupUser: builder.query<any, { filterDate: number }>({
      query: (data) => ({
        url: `/getEventGroupUser/${data.filterDate}`,
        method: 'GET',
      }),
      transformResponse: (res: any) => {
        return res.matched_groups
      },
    }),
    getAllRestaurants: builder.query<any, any>({
      query: (data) => ({
        url: `/getAllRestaurants`,
        method: 'GET',
      }),
      transformResponse: (res: any) => {
        return res
      },
    }),
    updateMatchedMroupsRestaurantDetail: builder.mutation<any, any>({
      query: (data) => ({
        url: '/updateMatchedMroupsRestaurantDetail',
        method: 'POST',
        body: data,
      }),
      transformResponse: (res: any) => {
        return res
      },
    }),

  })
})



export const {
  useLazyGetEventGroupUserQuery,
  useLazyGetAllRestaurantsQuery,
  useUpdateMatchedMroupsRestaurantDetailMutation,
} = extendedApi
