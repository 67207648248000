import logoImage from '../../../assets/images/Cielo.svg'



export default function Logo() {

  return (
    <img src={logoImage} alt='logo' />
  )
}
