import { useState } from 'react';
import { HeaderProps } from './Header.type'
import { AppBar, Avatar, Box, IconButton, Stack, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { MdMenu } from "react-icons/md";

import Logo from '../_ui/logo/Logo.component';
import AccountMenu from './components/Menu.component';
import { theme } from '../../utils';
import { Link } from 'react-router-dom';
import { useReduxSelector } from '../../hooks/redux.hook';
import { log } from 'console';


export default function Header({ setOpenDrawer, openDrawer }: HeaderProps) {
  const [accountMenuOpen, setAccountMenuOpen] = useState<any>(false);
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { profile } = useReduxSelector((state) => state.user);



  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };


  const handleAccountMenuToggle = () => {
    setAccountMenuOpen((prevState: any) => !prevState);
  };


  const handleAccountMenuClose = () => {
    setAccountMenuOpen(false);
  };
  return (
    <AppBar position="sticky" sx={{
      bgcolor: 'white.main',
      zIndex: (theme) => isMobileDevice ? theme.zIndex.drawer : theme.zIndex.drawer + 1
    }} >
      <Toolbar>
        <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerOpen} sx={{ mr: 5 }}>
          <MdMenu />
        </IconButton>

        <Stack display='flex' direction='row' justifyContent='space-between' alignItems='center' width={1}>
          <Box height={40} width={80} component={Link} to="/">
            <Logo />
          </Box>

          <Stack direction='row' alignItems='center'>
            <Typography variant='body3' className='capitalize' color='text.primary' fontWeight='600'>{profile?.full_name ? profile.full_name : ''}</Typography>
            <IconButton onClick={handleAccountMenuToggle} sx={{ marginLeft: '-8px' }}>
              <AccountMenu open={accountMenuOpen} onClose={handleAccountMenuClose}>
                <Avatar className='cursor-pointer'>A</Avatar>
              </AccountMenu>
            </IconButton>
          </Stack>

        </Stack>
      </Toolbar>
    </AppBar >
  )
}

