import { Grid, Stack, Typography } from '@mui/material';
import { FaCheckCircle, FaFlag, FaUserTimes } from 'react-icons/fa'; // Import icons from React Icons



const cardsData = [
  // { title: 'Total Users', count: "Comming soon", icon: <FaUsers size='2.5rem' color='white' />, bgColor: 'info.main' },
  // { title: 'Total Feedback', count: "Comming soon", icon: <FaComment size='2.5rem' color='white' />, bgColor: 'warning.dark' },
  // { title: 'Total Verified', count: "Comming soon", icon: <FaUserCheck size='2.5rem' color='white' />, bgColor: 'primary.main' },
  { title: 'Active Users', count: "Coming Soon", icon: <FaCheckCircle size='2.5rem' color='white' />, bgColor: 'success.main' },
  { title: 'Total Report', count: "Coming soon", icon: <FaFlag size='2.5rem' color='white' />, bgColor: 'secondary.main' },
  { title: 'Total disable', count: "Coming soon", icon: <FaUserTimes size='2.5rem' color='white' />, bgColor: 'error.main' },
];



const DashboardPage = () => {


  return (
    <>
      <Grid container spacing={3}>
        {cardsData.map((data, index) => (
          <Grid key={index} item xs={12} sm={6} lg={4}>
            <Stack sx={{ bgcolor: data.bgColor, borderRadius: 3 }}>

              <Stack p={4} direction='row' gap={3} >
                <Typography>{data.icon}</Typography>
                <Stack gap={3} sx={{ display: 'flex', alignItems: 'center' }} >
                  <Typography color='white.main' variant='h6' fontWeight={600}>{data.title}</Typography>
                  <Typography color='white.main' variant='h6' fontWeight={400}>{data.count}</Typography>
                </Stack>
              </Stack>

            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DashboardPage;
