import { Box } from '@mui/material'
import { AuthLayoutProps } from './AuthLayout.type'
import config from '../../config'
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary.component'
import { useTitle } from '../../hooks/useTitle.hook'



export default function AuthLayout(props: AuthLayoutProps) {
  const { children, title, header } = props
  useTitle(title)

  return (
    <>
      {/* Error boundary, if any component give an error, so it will show this component*/}
      <ErrorBoundary>
        <Box className='animate__animated animate__fadeIn animate__faster' >
          {children}
        </Box>
      </ErrorBoundary>
      {/* {footer !== false && <Footer />} */}

    </>
  )
}
