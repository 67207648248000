import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'



export const useColumns = () => {


  const columns: GridColDef<any>[] = [
    {
      field: 'S.No', headerName: 'No', renderCell: (params) => (
        <Typography variant='body2'>{params.row.sno + 1}</Typography>
      )
    },
    {
      field: 'fullName', headerName: 'Full Name', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography variant='body2'>{params.row?.full_name}</Typography>
      )
    },
    {
      field: 'message', headerName: 'Meassage', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography>{params.row?.message}</Typography>
      )
    },
    {
      field: 'createAt', headerName: 'Create date', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography>{moment(params.row?.created?.$date).format('LL')}</Typography>
      )
    },

    // { field: 'action', headerName: 'Action', width: 70, sortable: false, renderCell: (params) => <TableActions data={params.row} editUrl={`/blog/edit/${params.row.id}`} deleteCallBack={handleRemoveRow} /> },
  ]


  return { columns }
}