


export const style = {
  userName: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  }

}