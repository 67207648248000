import { Matched_Groups_Users } from "../../../../interface/matchGroups.interface";
import * as yup from 'yup'


export type RestaurantFormData = {
  eventId: string;
  group_id: string;
  restaurant_id: string;
  restaurant_title: any;
  restaurant_address: string;
  google_map_link: string;
  users?: Matched_Groups_Users[]
}

export type RestaurantFormDataProps = {
  formData: RestaurantFormData
  updateMatchFormLoading: boolean
  onUpdate: (data: RestaurantFormData) => void
  restaurantIndex: number
  allRestaurantData: any
  isAllRError: boolean
  isAllRFetching: boolean
  isAllRLoading: boolean
  defaultRestaurantObject: any
}


export const schema = yup.object({
  restaurant_title: yup.object(),
  restaurant_id: yup.string().trim().required().min(2).max(300),
  restaurant_address: yup.string().trim().required().min(2).max(300),
  google_map_link: yup.string().required('Google Map link is required.'),
})

export type FormDataType = yup.InferType<typeof schema>