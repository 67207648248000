import { useEffect, useState } from 'react'


export const useGetPageNumber = () => {

  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    const url = new URL(window.location.href)
    const pageParam: any = url.searchParams.get('page')
    const pageNumber = parseInt(pageParam || 0)
    setPageNumber(pageNumber)

  }, [window.location.href]);

  return pageNumber
}