
import { Box, Divider, Drawer, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material'
import Logo from '../../_ui/logo/Logo.component'
import { sidebarOption } from '../Sidebar.component'
import { Link } from 'react-router-dom'
import { SiderbarProps } from '../Siderbar.type'
import { theme } from '../../../utils'



export default function MobileSidebar(props: SiderbarProps) {
  const { openDrawer, setOpenDrawer } = props
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));


  return (
    <Drawer open={openDrawer} onClose={() => setOpenDrawer(!openDrawer)}>

      <Stack justifyContent='center' alignItems='center'>
        <Box height={200} width={200}>
          <Logo />
        </Box>

        <Box sx={{ width: 300 }} role="presentation" onClick={() => setOpenDrawer(false)}>
          <List>
            {sidebarOption.map((item, index) => (
              <Link to={item.link} key={index}>
                <ListItem disablePadding>
                  <Stack direction='row' py={2} px={3} gap={3} justifyContent='center'>
                    <Typography display='flex'>{item.icon}</Typography>
                    <Typography variant='body1' display='flex' fontWeight={500}>{item.title}</Typography>
                  </Stack>
                </ListItem>
              </Link>
            ))}
          </List>
          <Divider />
        </Box>
      </Stack>
    </Drawer>
  )
}
