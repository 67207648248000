// import React from 'react'
// import Layout from '../../layouts/globalLayout/Layout.page'

import { Alert, Container, Pagination, PaginationItem, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useColumns } from "./hooks/usersFeedback.hook"
import { useEffect } from "react"
import { useLazyGetAllUserFeedbackQuery } from "../../lib/redux/api/users.api"
import { useNavigate } from "react-router-dom"
import { useGetPageNumber } from "../../hooks/useGetPageNumber.hook"
import { useTitle } from "../../hooks/useTitle.hook"
import config from "../../config"
import PageHeading from "../../components/_ui/pageHeading/PageHeading.component"



export default function AllUserFeedback() {
  useTitle('Feedback User')
  const { columns } = useColumns()
  const navigate = useNavigate()
  const pageNumber = useGetPageNumber()


  const paginationModel = { page: Number(pageNumber || 1) - 1, pageSize: config.tableRowLimit }
  const [getAllUserFeedback, { data, isError, isLoading, isFetching, isUninitialized }] = useLazyGetAllUserFeedbackQuery()


  const setPaginationModel = (page: number) => {
    navigate(`/admin/users-feedback?page=${page}`)
  }


  useEffect(() => {
    getAllUserFeedback({
      page: (paginationModel.page + 1),
      page_size: paginationModel.pageSize,
      order_by: "created",
      order_type: "dsc",

    })
  }, [paginationModel.page])


  if (isError) return <Container><Alert severity="error" >Something Went Wrong</Alert></Container>


  return (
    <>
      <PageHeading>User Feedback</PageHeading>

      <Stack width={1}>
        {/* == Table ==  */}
        {isError ? <>Something Went Wrong</> :
          <DataGrid
            autoHeight
            columns={columns}
            rowSelection={false}
            paginationMode='server'
            disableColumnMenu={true}
            loading={isUninitialized || isFetching}
            paginationModel={paginationModel}
            rowCount={data?.total_feedback || 0}
            rows={data?.userfeedback?.map((item: any, index: number) => ({ sno: index, id: item?._id?.$oid, ...item })) || []}
            slots={{
              pagination: () => (
                <Pagination
                  page={paginationModel.page + 1}
                  onChange={(_, newPage) => setPaginationModel(newPage)}
                  count={data?.total_feedback ? Math.ceil(Number(data?.total_feedback) / paginationModel.pageSize) : 0}
                />
              ),
              noRowsOverlay: () => <Typography variant='body2' color='text.secondary' className='center' height={1}>No Record Found</Typography>
            }}
          />}
      </Stack>
    </>
  )
}
