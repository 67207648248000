import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "../../../utils";




// without login access pages
export default function AuthRoutes({ children }: { children?: ReactNode }) {

  {/* === Get Token === */ }
  let token: string | null = null
  if (typeof window !== 'undefined') token = getCookie('token') || null


  return !token ? <>{children}</> : <Navigate to='/' />
}