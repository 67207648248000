import { Divider, IconButton, List, ListItem, Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { MdDashboard, MdFeedback, MdMenu, MdTableRestaurant } from 'react-icons/md'
import { SiderbarProps } from './Siderbar.type';
import { FaUsers, FaUserAltSlash } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { Drawer, DrawerHeader, style } from './Sidebar.style';



export default function Sidebar({ openDrawer, setOpenDrawer }: SiderbarProps) {


  const theme = useTheme();
  const location = useLocation();


  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };


  return (
    <Drawer variant="permanent" open={openDrawer} sx={{ border: 'none', backgroundColor: 'transparent' }}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <MdMenu /> : <MdMenu />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {sidebarOption.map((item, index) => (
          <Link to={item.link} key={index}>
            <ListItem disablePadding sx={
              location?.pathname === item.link
                ? { ...style.sidebarListItem.withColor }
                : { ...style.sidebarListItem.withOutColor }
            }>
              <Stack direction='row' py={2} px={2} gap={2} justifyContent='center' alignContent='center'>
                <Typography display='flex'>{item.icon}</Typography>
                <Typography variant='body1' display='flex' fontWeight={500}>{item.title}</Typography>
              </Stack>
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer >
  )
}


export const sidebarOption = [
  {
    title: 'Dashboard',
    link: '/',
    icon: <MdDashboard fontSize='1.5rem' />,
    role: ['admin']
  },
  {
    title: 'Users',
    link: '/admin/all-users',
    icon: <FaUsers fontSize='1.5rem' />,
    role: ['admin']
  },
  {
    title: 'User Feedback',
    link: '/admin/users-feedback',
    icon: <MdFeedback fontSize='1.5rem' />,
    role: ['admin']
  },
  {
    title: 'Reported User',
    link: '/admin/users-abuse',
    icon: <FaUserAltSlash fontSize='1.5rem' />,
    role: ['admin']
  },
  {
    title: 'Update Restaurant',
    link: '/admin/update/restaurant',
    icon: <MdTableRestaurant fontSize='1.5rem' />,
    role: ['admin']
  },
]
