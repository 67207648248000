import React from 'react'
import DashboardPage from './component/Dashboard.compoent'
import { useTitle } from '../../hooks/useTitle.hook'
import PageHeading from '../../components/_ui/pageHeading/PageHeading.component'


export default function Dashboard() {
  useTitle('Dashboard')


  return (
    <>
      <PageHeading>Dashboard</PageHeading>
      <DashboardPage />
    </>
  )
}
