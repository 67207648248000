import { Typography } from '@mui/material'



const PageHeading = ({ variant, children }: any) => {


  return (
    <>
      <Typography variant={variant || 'h4'} m={2} fontWeight={400}>
        {children}
      </Typography>

    </>
  )
}

export default PageHeading
