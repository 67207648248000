import { createBrowserRouter } from "react-router-dom"
import { authRoutes } from "./auth.route"
import { publicRoutes } from "./public.route"
import { protectedRoutes } from "./protected.route"
import NotFoundPage from "../pages/not-found/index.page"



const routes = createBrowserRouter([
  ...authRoutes,
  ...publicRoutes,
  ...protectedRoutes,
  { path: '*', element: <NotFoundPage /> },
])


export default routes