import * as React from 'react';
import { Box, Avatar, Popover, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { MdLogout } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { removeCookie } from '../../../utils';
import { useReduxSelector } from '../../../hooks/redux.hook';
import { useNavigate } from 'react-router-dom';
import { styles } from './Menu.style';

export default function AccountMenu({ open }: any) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { profile } = useReduxSelector((state) => state.user);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        if (profile?._id?.$oid) {
            navigate(`/admin/users-detail/${profile._id.$oid}`);
        }
        handleClose();
    };

    const handleLogoutClick = () => {
        removeCookie('token');
        window.location.reload();
    };

    return (
        <>
            <Box sx={{ ml: 2 }}>
                <IconButton onClick={handleClick} size="small" aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                    <Avatar sx={{ width: 32, height: 32 }}>A</Avatar>
                </IconButton>
            </Box>

            <Popover
                PaperProps={{ sx: styles.menu }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{ left: '12px', top: '-12px' }}
            >
                <Box sx={{ p: 1 }}>
                    <MenuItem onClick={handleProfileClick}>
                        <ListItemIcon>
                            <CgProfile size='1.5rem' />
                        </ListItemIcon>
                        <ListItemText primary="Profile" />
                    </MenuItem>
                    <MenuItem onClick={handleLogoutClick}>
                        <ListItemIcon>
                            <MdLogout size='1.5rem' />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </MenuItem>
                </Box>
            </Popover>
        </>
    );
}
