import { api } from './api.config'
import { updateProfile } from '../slices/user.slice'
import { setCookie } from '../../../utils/index'
import { LoginDto } from '../../../dtos/Login.dto'
import { AllUsersDto } from '../../../dtos/AllUsers.dto'



export const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    authLogin: builder.mutation<LoginDto, { email: String, password: String }>({
      query: (data) => ({
        url: '/login',
        method: 'POST',
        body: data
      }),
      transformResponse: (res: LoginDto) => {
        saveUser(res.token)
        console.log(res)
        return res;

      },
    }),

    getUserByToken: builder.query<any, any>({
      query: (token) => `/getUserDetailByToken`,
      transformResponse: (res: AllUsersDto) => res,
      providesTags: ['profile'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled.then(({ data }) => { console.log(data); dispatch(updateProfile(data)) })
      }
    }),

  })
})


const saveUser = (token: string) => {
  console.log(token)
  if (!!token) {
    setCookie('token', token, 30)
    window.location.href = '/'
  }
}



export const {
  useAuthLoginMutation,
  useLazyGetUserByTokenQuery,
} = extendedApi


export interface IRegister {
  email: string
  password: string
  role: string
  username: string
}