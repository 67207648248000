import { ReactNode } from "react";
import Layout from "../../../layouts/globalLayout/Layout.page";
import { useAuthentication } from "./protected.hooks";
import WebsiteLoader from "../../../components/_ui/websiteLoader/WebsiteLoader.component";
// import { Navigate } from "react-router-dom";
// import { useReduxSelector } from "../../hooks/redux";



// with login access pages
export default function ProtectedRoute({ children }: { children?: ReactNode }) {
  // const { isLogggedIn } = useReduxSelector(state => state.auth)

  // if (isLogggedIn) {
  //   return children
  // }
  // else {
  //   return <Navigate to={`/auth/login?redirect_to=${window.location.pathname}`} />
  // }

  const { isLoading } = useAuthentication()


  if (isLoading) return <WebsiteLoader />

  return <Layout>{children}</Layout>
}