import { Outlet, RouteObject } from 'react-router-dom'
import Dashboard from '../pages/dashboard/index.page'
import ProtectedRoute from './components/protected/ProtectedRoutes'
import AllUsers from '../pages/allUsers/index.page'
import UserDetail from '../pages/userDetail/index.page'
import UserAbuse from '../pages/userAbuse/index.page'
import UserFeedBack from '../pages/userFeedback/index.page'
import UpdateRestaurant from '../pages/updateRestaurant/index.page'



export const protectedRoutes: RouteObject[] = [
  {
    path: '/', element: <ProtectedRoute><Dashboard /></ProtectedRoute>
  },
  { path: '/thank-you', element: <ProtectedRoute><>Thank you</></ProtectedRoute> },
  {
    path: '/admin',
    element: <ProtectedRoute><Outlet /></ProtectedRoute>,
    children: [
      { path: 'all-users', element: <AllUsers /> },
      { path: 'users-detail/:id', element: <UserDetail /> },
      { path: 'users-abuse', element: <UserAbuse /> },
      { path: 'users-feedback', element: <UserFeedBack /> },
      { path: 'update/restaurant', element: <UpdateRestaurant /> },
    ]
  },

]