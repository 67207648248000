import { Button, Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'
import { GoDotFill } from "react-icons/go";
import moment from 'moment'



export const useColumns = () => {
  const navigate = useNavigate()

  const columns: GridColDef<any>[] = [
    {
      field: 'S.No', headerName: 'No', minWidth: 130, renderCell: (params) => (
        <Typography variant='body2'>{params.row.sno + 1}</Typography>
      )
    },
    {
      field: 'fullName', headerName: 'Full Name', sortingOrder: ['desc', 'asc', null], minWidth: 140, flex: 1, renderCell: (params) => (
        <Typography variant='body2'>{params.row?.full_name}</Typography>
      )
    },
    {
      field: 'email', headerName: 'Email', flex: 1, minWidth: 180, renderCell: (params) => (
        <Typography sx={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>{params.row?.email}</Typography>
      )
    },
    {
      field: 'status', headerName: 'Status', flex: 1, minWidth: 130, renderCell: (params) => {
        return <Stack direction='row' gap={1} justifyContent='center' alignItems='center' >
          {params.row?.status === 'active' ? <>
            <GoDotFill color='green' />
            <Typography color=''>Active</Typography>
          </>
            : params.row?.status === 'blocked' ? (
              <>
                <GoDotFill color='red' />
                <Typography color='error.main'>Blocked</Typography>
              </>
            ) : <>
              <GoDotFill color='red' />
              <Typography color='error.main'>Deactive</Typography>
            </>
          }
        </Stack>
      }
    },
    {
      field: 'is_host_verified',
      headerName: 'Verify User',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        const statusMap: any = {
          approved: { label: 'Approved', color: 'success.main' },
          disapproved: { label: 'Disapproved', color: 'error.main' },
          pending: { label: 'Pending', color: 'warning.main' },
          not_applied: { label: 'Not Applied', color: 'grey.500' },
        };
        const status = statusMap[params.row?.is_host_verified] || statusMap.not_applied;

        return <Typography color={status.color}>{status.label}</Typography>;
      },
    },

    {
      field: 'create date', headerName: 'Create Date', flex: 1, minWidth: 130, renderCell: (params) => (
        <Typography>{moment(params.row?.created?.$date).format('DD/MM/YYYY')}</Typography>
      )
    },

    {
      field: 'action', headerName: 'Action', minWidth: 130, renderCell: (params) => (
        <>
          <Button onClick={() => navigate(`/admin/users-detail/${params.id}`)}>View</Button>
        </>
      )
    },

    // { field: 'action', headerName: 'Action', width: 70, sortable: false, renderCell: (params) => <TableActions data={params.row} editUrl={`/blog/edit/${params.row.id}`} deleteCallBack={handleRemoveRow} /> },
  ]


  return { columns }
}