import developmentConfig from './dev.json'
import productionConfig from './prod.json'
import stagingConfig from './staging.json'
let config: any;


switch (process.env.REACT_APP_ENV) {
  case 'development': config = developmentConfig
    break;
  case 'production': config = productionConfig
    break;
  case 'staging': config = stagingConfig
    break;
  default:
    config = developmentConfig
}

export default config
