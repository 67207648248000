import { useState } from 'react'
import { LayoutProps } from './Layout.type'
import { useTitle } from '../../hooks/useTitle.hook'
import { Box, Card, Container, Stack, useMediaQuery } from '@mui/material'
import Header from '../../components/header/Header.component'
import Sidebar from '../../components/sidebar/Sidebar.component'
import ErrorBoundary from '../../components/errorBoundary/ErrorBoundary.component'
import MobileSidebar from '../../components/sidebar/mobileSidebar/MobileSidebar.component'
import { theme } from '../../utils'



export default function Layout(props: LayoutProps) {
  const { children, title, header } = props
  useTitle(title)

  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const [openDrawer, setOpenDrawer] = useState(!isMobileDevice)


  return (
    <>

      {/* Header */}
      {header !== false && <Header openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />}

      <Box display='flex'>

        {/* sidebar toogle */}
        {
          isMobileDevice ?
            <MobileSidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
            : <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
        }

        <Stack flex={1} overflow='hidden'>
          <Container>
            {/* Error boundary, if any component give an error, so it will show this component*/}
            <Box className='animate__animated animate__fadeIn animate__faster'>
              <Card sx={{ boxShadow: 5, borderRadius: 2, minHeight: 'calc(100vh - 130px)', top: 20, px: 4, py: 2, my: 4 }}>
                <ErrorBoundary>
                  {children}
                </ErrorBoundary>
              </Card>
            </Box>
          </Container >
        </Stack>
        {/* {footer !== false && <Footer />} */}

      </Box>
    </>
  )
}
