


export const style = {
  mainBox: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    maxWidth: 'auto',
    margin: 'auto',
    padding: '20px',
  },
}