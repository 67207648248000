import { useState, useEffect } from 'react'
import { ProfileDTO } from '../../../dtos/Profile.dto'
import { useLazyGetUserByTokenQuery } from '../../../lib/redux/api/auth.api'
import { getCookie, removeCookie } from '../../../utils'
import { useNavigate } from 'react-router-dom'



export const useAuthentication = () => {

  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState<ProfileDTO>()
  const navigate = useNavigate()
  const [getUserByToken, { isError, isLoading, isFetching }] = useLazyGetUserByTokenQuery()


  {/* === Get Token === */ }
  let token: string | null = null
  if (typeof window !== 'undefined') token = getCookie('token') || null


  {/* === Check User === */ }
  useEffect(() => {
    if (token) {
      getUserByToken(token).unwrap()
        .then((res) => {
          setUserData(res.user)
          setLoading(false)
        })
    }
  }, [])


  {/* === Redirection === */ }
  useEffect(() => {
    if (!token) {
      navigate('/auth/login')
      setLoading(false)
    }
  }, [userData])



  return {
    isLoading: loading || isLoading || isFetching,
    isError,
  }
}