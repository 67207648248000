import { Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import moment from 'moment'
import TableActions from '../components/tableActions/TableActions.component'



export const useColumns = () => {

  const columns: GridColDef<any>[] = [
    {
      field: 'S.No', headerName: 'No', renderCell: (params) => (
        <Typography variant='body2'>{params.row.sno + 1}</Typography>
      )
    },
    {
      field: 'group id', headerName: 'Group Id', flex: 1, minWidth: 200, renderCell: (params) => (
        <Typography variant='body2'>{params.row.group_id}</Typography>
      )
    },
    {
      field: 'reported username', headerName: 'Reported User', flex: 1, minWidth: 200, renderCell: (params) => (
        <Typography variant='body2'>{params.row?.reportedUser[0]?.full_name}</Typography>
      )
    },
    {
      field: 'reported by username', headerName: 'Reported By', flex: 1, minWidth: 200, renderCell: (params) => (
        <Typography>{params.row?.reportedByUser[0]?.full_name}</Typography>
      )
    },
    {
      field: 'report date', headerName: 'Reported Date', flex: 1, minWidth: 200, renderCell: (params) => (
        <Typography>{moment(params.row?.reported_date?.$date).format('LL')}</Typography>
      )
    },

    {
      field: 'action', headerName: 'Action', renderCell: (params) => (<TableActions data={params.row} />)
    },]


  return { columns }
}