


export const style = {
  avatar: {
    bgcolor: 'primary.main',
    width: 100,
    height: 100,
    margin: 0
  },
  userDetailHeading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },

  stackItem: {
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.5rem',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  socialLink: {
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden'

  }


}