import { LoadingButton } from '@mui/lab';
import { Alert, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { MobileDatePicker } from '@mui/x-date-pickers'
import { RestaurantFormData } from './components/restaurantForm/RestaurantForm.type';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyGetAllRestaurantsQuery, useLazyGetEventGroupUserQuery, useUpdateMatchedMroupsRestaurantDetailMutation } from '../../lib/redux/api/restaurants.api';
import { FormDataType, schema } from './UpdateRestaurant.config';
import RestaurantForm from './components/restaurantForm/RestaurantForm.component';
import moment from 'moment-timezone';
import Loader from '../../components/_ui/Loader/Loader.components';
import PageHeading from '../../components/_ui/pageHeading/PageHeading.component';
import { useEffect } from 'react';


export default function UpdateRestaurant() {

  const [getAllRestaurants, { data: allRestaurantData, isLoading: isAllResaurantLoading, isFetching: isAllResaurantFetching, isError: isErrorAllRestaurant }] = useLazyGetAllRestaurantsQuery()

  useEffect(() => {
    getAllRestaurants('')
  }, [])

  const { control, handleSubmit, formState: { errors } } = useForm<FormDataType>({ resolver: yupResolver(schema) })

  const [getEventGroupUser, { data: matched_groups, isError, isSuccess, isLoading, isFetching }] = useLazyGetEventGroupUserQuery()
  const [updateMatchedMroupsRestaurantDetail, { isLoading: updateMatchFormLoading }] = useUpdateMatchedMroupsRestaurantDetailMutation()


  const onSubmit = (data: FormDataType) => {
    const timeStam = new Date(data.selectDate);
    const newTimeStam = moment(timeStam).add(19, 'hours');
    const pstTime = newTimeStam.tz('America/Los_Angeles').valueOf();
    getEventGroupUser({ filterDate: pstTime })
  }

  const handleUpdate = async (formData: RestaurantFormData) => {
    const { restaurant_title, ...rest } = formData
    await updateMatchedMroupsRestaurantDetail({ ...rest, restaurant_title: restaurant_title.Name || '' });
  };


  return (
    <>
      {/* ==Heading== */}
      <PageHeading>Update Restaurant</PageHeading >

      {/* ==Form== */}
      <Stack component='form' onSubmit={handleSubmit(onSubmit)} >
        <Stack direction='row' gap={4} mb={3} maxWidth={600}>

          {/* Date Picker */}

          <Controller name='selectDate' control={control}
            render={({ fieldState: { error }, field }) =>
              <MobileDatePicker {...field} label='Select Date' maxDate={moment().add(5, 'days')}
                value={field.value ? moment(field.value) : undefined}
                onChange={value => { field.onChange(moment(value).format()) }}
                slotProps={{
                  textField: { error: error ? Boolean(error) : undefined, helperText: error?.message, variant: 'filled' }
                }}
              />
            }
          />

          {/* Button Submit Date */}
          <Stack>
            <LoadingButton variant='contained' loading={isLoading || isFetching} type='submit' sx={{ minWidth: 200, minHeight: 55 }}>Get Data</LoadingButton>
          </Stack>

        </Stack>
      </Stack >


      {/* == Data Fetch Loader and handling error ==  */}
      {isLoading || isFetching && <Loader />}
      {!isFetching && !Boolean(matched_groups?.length) && isSuccess && <Alert severity="info">No Record Found</Alert>}
      {!isFetching && isError && <Alert severity="error">Something Went Wrong</Alert>}


      {/* == Data And Restaurant Form ==  */}
      {
        !isFetching && matched_groups && matched_groups.map((item: any, index: number) => (
          <RestaurantForm key={index}
            restaurantIndex={index}
            formData={item}
            updateMatchFormLoading={updateMatchFormLoading}
            onUpdate={(data) => handleUpdate(data)}
            allRestaurantData={allRestaurantData}
            isAllRError={isErrorAllRestaurant}
            isAllRFetching={isAllResaurantFetching}
            isAllRLoading={isAllResaurantLoading}
            defaultRestaurantObject={allRestaurantData.restaurants_data.filter((data: any) => item.restaurant_id == data._id.$oid)}
          />
        ))
      }
    </>
  )
}
