import { useEffect, useState } from 'react'
import { Alert, Box, Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material'
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { MdVerified } from "react-icons/md";
import { useParams } from 'react-router'
import { useApproveUserProfileMutation, useLazyGetUserDetailUserIdQuery, useUpdatedHostVerifiedStatusMutation } from '../../../lib/redux/api/users.api'
import { Link } from 'react-router-dom';
import { style } from './UserDetail.style';
import { LoadingButton } from '@mui/lab';
import blankImage from '../../../assets/images/blankImage.png'
import Loader from '../../../components/_ui/Loader/Loader.components'
import config from '../../../config';
import { theme } from '../../../utils';
import ConfirmationPopup from '../../../components/_ui/confirmationPopup/ConfirmationPopup.components';
import BackButton from '../../../components/_ui/backButton/BackButton.compoent';
import { useTitle } from '../../../hooks/useTitle.hook';



export default function UserDetailPage() {
  const [getUserDetailUserId, { isLoading, data, isError }] = useLazyGetUserDetailUserIdQuery();
  // const [approveUserProfile, { isLoading: ApproveUserLoading }] = useApproveUserProfileMutation()
  const [approveUserProfile, { isLoading: VerifiedHostLoading }] = useUpdatedHostVerifiedStatusMutation()

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationType, setConfirmationType] = useState('');
  const [userIdToConfirm, setUserIdToConfirm] = useState('');
  const { id } = useParams();

  useTitle('User Detail')

  useEffect(() => {
    if (id) {
      getUserDetailUserId({ id: id as string });
    }

  }, [id]);

  const handleApproveUser = (isHostVerified: string) => {
    if (isHostVerified === 'approved') {
      handleConfirmation(data?._id.$oid as string, 'unverify');
    } else {
      handleConfirmation(data?._id.$oid as string, 'verify');
    }
  };

  const handleConfirmation = (userId: string, type: string) => {
    setConfirmationOpen(true);
    setConfirmationType(type);
    setUserIdToConfirm(userId);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const performUserAction = (isVerified: boolean) => {
    // approveUserProfile({ userId: userIdToConfirm, is_verified: isVerified ? 'true' : 'false' });
    approveUserProfile({ user_id: userIdToConfirm, status: isVerified ? 'approved' : 'disapproved' });
    handleCloseConfirmation();
  };

  if (isLoading) { return <Loader /> }
  if (isError) return <Container><Alert severity="error" >Something Went Wrong</Alert></Container>


  return (
    <>
      <Box component='span' width={2}><BackButton path='/admin/all-users'>Back</BackButton></Box>
      <Stack gap={3}>
        <Grid container spacing={3} mb='2'>

          {/* User Profile Card */}
          <Grid item xs={12} md={6} lg={6}>
            <Stack alignItems='center' gap={3} p={2}>
              <Stack justifyContent='start' alignItems='center' gap={2}>
                <Box height={200} width={200} borderRadius={3} overflow='hidden'>
                  {data?.profile_pictures && data.profile_pictures?.length > 0 ? (
                    <img src={`${config?.apiBaseUrl}/${data.profile_pictures[0]}`} alt='image' style={{ objectFit: 'cover' }} />
                  ) : (
                    <img src={blankImage} alt='image' style={{ objectFit: 'cover' }} />
                  )}
                </Box>

                <Typography variant='h5' textAlign='center'>{data?.full_name}</Typography>
                <Typography variant='body1' textAlign='center'>{data?.email}</Typography>
              </Stack>
              {data?.full_name !== 'admin' &&
                <LoadingButton sx={{ width: 300 }} loading={VerifiedHostLoading} variant='outlined'
                  color={data?.is_host_verified == 'approved' ? 'error' : 'success'}
                  onClick={() => handleApproveUser(data?.is_host_verified)}>
                  {data?.is_host_verified == 'approved' ? 'unverify' : 'verify'}
                </LoadingButton>
              }
            </Stack>

          </Grid>



          {/* User Detail Page */}
          <Grid item xs={12} md={6} lg={6}>
            <Box p={2}>
              <Typography variant='h6' fontWeight={600} sx={style.userDetailHeading}>User Details
                <Box component='span' px={1} mt={1} fontSize={30}>
                  {data?.is_host_verified && <MdVerified color={theme.palette.success.main} />}
                </Box>
              </Typography>

              <Stack spacing={2} alignItems='flex-start'>

                <Stack direction='row' gap={3} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' fontWeight={700}>Status:</Typography>
                  {/* <Typography variant='body1' color={data?.status === 'active' ? 'success.main' : 'error.main'}
                  >{data?.status ? 'Active' ' : 'Status is not available'}</Typography> */}
                  {data?.status === 'active' ? <Typography color='success.main'>Active</Typography>
                    : data?.status === 'blocked' ? <Typography color='error.main'>Blocked</Typography>
                      : <Typography color='error.main'>Deactive</Typography>
                  }

                </Stack>

                <Stack direction='row' gap={3} alignItems='center' justifyContent='space-around' maxWidth='auto'>
                  <Typography variant='body2' fontWeight={700} >Phone:</Typography>
                  <Typography variant='body1'>{data?.phone ? data?.phone : 'Phone number is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={6} alignItems='center' justifyContent='space-' maxWidth='auto'>
                  <Typography variant='body2' fontWeight={700} alignSelf='flex-start' >Bio:</Typography>
                  <Typography variant='body1'>{data?.bio?.trim() ? data?.bio?.trim() : 'Bio is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={4.5} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' fontWeight={700} >DOB:</Typography>
                  <Typography variant='body1'>{data?.dob ? data?.dob : 'DOB is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={4.5} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' alignSelf='start' fontWeight={700} >Address:</Typography>
                  <Typography variant='body1'>{data?.city ? data?.city : 'Address is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={4.5} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' alignSelf='start' fontWeight={700} minWidth={100}>Latitude:</Typography>
                  <Typography variant='body1'>{data?.geolocation?.lat ? data?.geolocation?.lat : 'Latitude is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={4.5} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' alignSelf='start' fontWeight={700} minWidth={100}>Longitude:</Typography>
                  <Typography variant='body1'>{data?.geolocation?.long ? data?.geolocation?.long : 'Longitude is not available'}</Typography>
                </Stack>

                <Stack direction='row' gap={6.8} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' color='error.light' fontWeight={700}>  <FaInstagram size='1.5rem' /> </Typography>
                  <Typography variant='body1' sx={style.socialLink}>
                    {data?.instagram_link ? <Link to={data?.instagram_link}>{data?.instagram_link} </Link> : 'Not available'}
                  </Typography>
                </Stack>

                <Stack direction='row' gap={6.8} alignItems='center' maxWidth='auto'>
                  <Typography variant='body2' color='primary' fontWeight={700}> <FaLinkedin size='1.5rem' /></Typography>
                  <Typography variant='body1' sx={style.socialLink}>
                    {data?.linkedin_link ? <Link to={data?.linkedin_link}>{data?.linkedin_link}</Link> : 'Not available'}
                  </Typography>
                </Stack>
              </Stack>

            </Box>
          </Grid>
        </Grid>

        <Divider />
        <Grid container spacing={3} columnSpacing={2}>

          {/* Questions and Answers Card */}
          {data?.questions_response &&
            <Stack p={3}>
              <Typography variant='h5' mb={3}>Questions & Answers</Typography>

              <Stack gap={1} direction='column'>
                {data?.questions_response?.map((item: any, index: number) => (
                  <>
                    <Stack key={item?._id} direction='column' spacing={1}>
                      {/* <Typography variant='body3' component='span'>Question : {index + 1}</Typography> */}
                      <Typography variant='h6'>{index + 1}. {item?.question}</Typography>
                      <Stack direction='row' gap={0.5} flexWrap='wrap' rowGap={0.5}>
                        {item?.answer && item?.answer.map((answer: string, index: number) =>
                          <Chip key={index} label={answer} color='primary' />)}

                        {!Boolean(item?.answer?.length) && <Typography variant='body1'>No answer provided</Typography>}
                      </Stack>
                    </Stack>
                    <Divider />
                  </>
                ))}
              </Stack>
            </Stack>
          }
        </Grid>

        {/* Confiramtion Alert */}
        <ConfirmationPopup
          isOpen={confirmationOpen}
          handleClosePopup={handleCloseConfirmation}
          title={`Are you sure you really want to ${confirmationType === 'unverify' ? 'unverify' : 'verify'} ${data?.full_name ? data?.full_name : 'this user'}  ?`}
          description={`This action will ${confirmationType === 'unverify' ? 'unverify' : 'verify'} the user. `}
          callback={() => performUserAction(confirmationType === 'verify')}
          closeButtonTitle="Cancel"
          actionButtonTitle={confirmationType === 'unverify' ? 'Unverify' : 'Verify'}
        />

      </Stack >
    </>
  );
}
